
.row.results[data-v-3ed498ca] {
  margin-top: 20px;
}


.row.results[data-v-c8b3213e] {
  margin-top: 20px;
}

